<template>
  <div class="std-layout background-white">
    <div class="top-bar">
      <div class="logo-home">
        <img
          class="logo-mark"
          src="../assets/logo.svg"
          width="38"
          height="38"
        />
        <div class="logo-name background-white">BPG BUILDERS</div>
      </div>
      <div class="controls">
        <label>Contact: +27 611353502</label>
      </div>
    </div>
    <div class="content">
      <div class="home">
        <div class="banner color-white">
          <div class="banner-left">
            <h1 class="banner-title">
              HOME RENOVATIONS &amp; MINOR BUILDING WORK
            </h1>
            <h2 class="banner-tagline">
              SERVING THE ATLANTIC SEABOARD &amp; SOUTHERN SUBURBS
            </h2>
          </div>
          <div class="banner-right">
            <img
              class="logo-mark"
              src="../assets/banner.svg"
              width="300"
              height="300"
            />
          </div>
        </div>
        <div class="content-block">
          <h1 class="content-block-header background-white">
            QUALITY WORK AT AFFORDABLE PRICES
          </h1>
          <p class="content-block-content background-white">
            BPG Builders is here to help turn your dream project into reality.
            Whether you are doing minor home improvements or sweeping
            renovations, we have you covered. We are an experienced team of
            builders that pride ourselves in high quality work at affordable
            prices. Our primary service areas are the Atlantic Seaboard and
            Southern Suburbs.
          </p>
        </div>
        <div class="how background-light">
          <h1 class="content-block-header">OUR SERVICES</h1>
          <div class="how-content">
            <div class="how-item">
              <img class="how-icon" src="../assets/community.svg" />
              <div class="how-text">
                <h3 class="how-heading">MASONRY</h3>
                <p>
                  We can assist with brick and blockwork, as well as stone
                  masonry.
                </p>
              </div>
            </div>
            <div class="how-item">
              <img class="how-icon" src="../assets/follow.svg" />
              <div class="how-text">
                <h3 class="how-heading">PLASTERING &amp; PAINTING</h3>
                <p>
                  Whether you need the exterior or interior of your house
                  plastered or painted, we have you covered.
                </p>
              </div>
            </div>
            <div class="how-item">
              <img class="how-icon" src="../assets/insight.svg" />
              <div class="how-text">
                <h3 class="how-heading">TILING &amp; PAVING</h3>
                <p>
                  We can assist with the tiling of floors and walls, as well as
                  paving services.
                </p>
              </div>
            </div>
            <div class="how-item">
              <img class="how-icon" src="../assets/carpentry.svg" />
              <div class="how-text">
                <h3 class="how-heading">CARPENTRY &amp; DECKING</h3>
                <p>
                  Our carpentry services cover ceilings, drywalling, doors and
                  decking.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="portfolio background-white">
          <h1 class="content-block-header">OUR PORTFOLIO</h1>
          <div class="portfolio-content">
            <div class="img-panel">
              <img class="portfolio-img" src="../assets/Braai.jpg" />
              <label class="img-caption">Entertainment area new build</label>
            </div>
            <div class="img-panel">
              <img class="portfolio-img" src="../assets/Bath.jpg" />
              <label class="img-caption">Bath platform and installation</label>
            </div>
            <div class="img-panel">
              <img class="portfolio-img" src="../assets/Bathroom.jpg" />
              <label class="img-caption"
                >Rain shower installation and tiling</label
              >
            </div>
            <div class="img-panel">
              <img class="portfolio-img" src="../assets/Bedroom.jpg" />
              <label class="img-caption">Bedroom remodel</label>
            </div>
            <div class="img-panel">
              <img class="portfolio-img" src="../assets/Balcony.jpg" />
              <label class="img-caption">Balcony and railing</label>
            </div>
            <div class="img-panel">
              <img class="portfolio-img" src="../assets/Team.jpg" />
              <label class="img-caption">The BPG team hard at work</label>
            </div>
          </div>
        </div>
        <div class="content-block background-light">
          <h1 class="content-block-header background-light">CONTACT US</h1>
          <p class="content-block-content background-light">
            Phone or WhatsApp Peter Gobede: +27 611353502
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
.std-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.top-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  position: sticky;
}

.logo-home {
  display: flex;
  flex-direction: row;
  font-size: 28px;
  font-weight: 600;
  color: #ffffff;
  height: 90px;
  padding-left: 75px;
  padding-right: 10px;
  cursor: pointer;
  flex-basis: 100%;
}

.logo-mark {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  height: 100%;
  vertical-align: middle;
}

.logo-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.controls {
  display: flex;
  justify-content: flex-end;
  flex-basis: 100%;
  align-items: center;
  margin-left: 15px;
  margin-right: 75px;
}

.content {
  width: 100%;
  height: calc(100vh - 100px);
  overflow: auto;
}

.home {
  display: column;
  width: 100%;
  height: 100%;
}

.banner {
  display: flex;
  width: 100%;
  background: var(--dark-gray);
  background: linear-gradient(-60deg, var(--dark-gray) 45%, var(--cta) 45%);
}

.banner-left {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding-left: 75px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.banner-title {
  font-size: 35px;
  font-weight: 500;
}

.banner-tagline {
  margin-top: 25px;
  font-size: 20px;
  font-weight: 500;
}

.get-started {
  margin-top: 50px;
  width: 140px;
  font-size: 16px;
}

.banner-right {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
}

.content-block {
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 25px;
  padding-right: 25px;
  align-items: center;
}

.content-block-header {
  font-size: 35px;
  font-weight: 500;
  padding-bottom: 40px;
}

.content-block-content {
  line-height: 2;
  text-align: justify;
  max-width: 650px;
}

.how {
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 25px;
  padding-right: 25px;
  align-items: center;
}

.how-content {
  display: flex;
  flex-direction: column;
}

.how-item {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.how-icon {
  margin-right: 50px;
  width: 60px;
}

.how-text {
  display: flex;
  flex-direction: column;
  min-width: 650px;
}

.how-heading {
  margin-bottom: 5px;
  min-width: 250px;
}

.portfolio {
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 25px;
  padding-right: 25px;
  align-items: center;
}

.portfolio-content {
  display: flex;
  flex-direction: column;
}

.img-panel {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
}

.portfolio-img {
  width: 700px;
  height: 500px;
}

@media only screen and (max-width: 768px) {
  .logo-home {
    padding-left: 25px;
  }

  .controls {
    margin-right: 25px;
  }
  .banner-right {
    display: none;
  }
  .banner {
    background: var(--cta);
  }
  .banner-left {
    padding-left: 25px;
    padding-right: 25px;
  }
  .how-text {
    min-width: 0px;
  }
  .how-icon {
    margin-right: 25px;
    width: 50px;
  }
  .portfolio-img {
    width: 100%;
    height: 200px;
  }
  .img-panel {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
